<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
          <div :class="transferStatus === '' ? 'active' : 'none'" @click="btnClick('')">
            全部({{ numHandle(numView.allNum ? numView.allNum : 0) }})
          </div>
          <div :class="transferStatus === '1' ? 'active' : 'none'" @click="btnClick('1')">
            待审核({{ numHandle(numView.waitNum ? numView.waitNum : 0) }})
          </div>
          <div :class="transferStatus === '2' ? 'active' : 'none'" @click="btnClick('2')">
            放款中({{ numHandle(numView.ingNum ? numView.ingNum : 0) }})
          </div>
          <div :class="transferStatus === '3' ? 'active' : 'none'" @click="btnClick('3')">
            已驳回({{ numHandle(numView.rejectNum ? numView.rejectNum : 0) }})
          </div>
          <div :class="transferStatus === '4' ? 'active' : 'none'" @click="btnClick('4')">
            放款成功({{ numHandle(numView.successNum ? numView.successNum : 0) }})
          </div>
          <div :class="transferStatus === '5' ? 'active' : 'none'" @click="btnClick('5')">
            放款失败({{ numHandle(numView.failNum ? numView.failNum : 0) }})
          </div>
          <div :class="transferStatus === '6' ? 'active' : 'none'" @click="btnClick('6')">
            已失效({{ numHandle(numView.invaNum ? numView.invaNum : 0) }})
          </div>
          <div :class="transferStatus === '7' ? 'active' : 'none'" @click="btnClick('7')">
            微信异常({{ numHandle(numView.wechatHandlingNum ? numView.wechatHandlingNum : 0) }})
          </div>
          <div :class="transferStatus === '8' ? 'active' : 'none'" @click="btnClick('8')">
            黑名单({{ numHandle(blacktotal || 0) }})
          </div>
        </div>
      </div>
      <div>
        <el-button size="small" type="danger" icon="el-icon-plus" @click="putBlacklist">加入黑名单</el-button>
        <el-button size="small" :loading="isExport" type="success" icon="el-icon-download" @click="clickExcel">导出账单</el-button>
      </div>
    </div>
    <GlobalTable ref="GlobalTable" v-if="transferStatus != '8'" v-loading="loading" :columns="tableColumns"
      :data="this.seachDataList" :currentPage="page.pageNum" :total="page.total"
      @handleCurrentChange="handleCurrentChange">
      <el-table-column label="类型" slot="bizType" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.bizType == '01' ? '付款到用户' : row.bizType == '02' ? '个人提现' : row.bizType == '03' ? '公司提现' : '领取返佣'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="关联订单号" slot="relationNo" align="center">
        <template slot-scope="{ row }">
          <div style="color: #0981ff;cursor: pointer;" @click="toOrderDetails(row)" v-if="row.relationNo"><span
              style="border-bottom: 1px solid #0981ff">{{ row.relationNo }}</span></div>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column label="角色" slot="staffRole" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.staffIsMajor ? '创建人' : row.staffRole == '01' ? '子账号' : row.staffRole == '02' ? '总监' : row.staffRole
            == '03' ? '店长' : row.staffRole == '04' ? '店员' : '用户' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款账号/业务单号" slot="staffPhone" align="center" width="90px">
        <template slot-scope="{ row }">
          <span v-if="row.transferChannel == 'wechat'">{{ row.staffPhone }}</span>
          <span v-else>{{ row.alipayOrderId || row.alipayAccount || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="金额" slot="amount" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" slot="transferStatus" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.transferStatus == '1' ? '待审核' : row.transferStatus == '2' ? '放款中' : row.transferStatus == '3' ?
            '已驳回' : row.transferStatus == '4' ? '放款成功' : row.transferStatus == '5' ? '放款失败' : row.transferStatus == '6' ?
              '已失效' : '微信异常' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否需审" slot="auditType" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.auditType == '00' ? '免审核' : '需审核' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注" width="130rpx" slot="note" align="center">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" v-if="row.note" :content="row.note" placement="top">
            <div class="Remarks">
              {{ row.note }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="失败原因" width="130rpx" slot="errorMsg" align="center"
        v-if="transferStatus === '5' || transferStatus === '6' || transferStatus === '' || transferStatus === '7'">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" v-if="row.errorMsg" :content="row.errorMsg" placement="top">
            <div class="Remarks">
              {{ row.errorMsg }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="收款方式" slot="transferChannel" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.transferChannel == 'wechat' ? '微信' : row.transferChannel == 'alipay' ? '支付宝' : '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否首次提现" slot="firstPrice" width="100rpx" align="center" v-if="transferStatus === '1'">
        <template slot-scope="{ row }">
          <div v-if="row.isFirstDeposit">
            <span style="color: red;">是</span>
            <div @click="examineId(row)" style="color: #0981ff;cursor: pointer;text-decoration: underline;">查看openID</div>
          </div>
          <span v-else-if="row.isFirstDeposit === false">否</span>
          <span v-else-if="row.isFirstDeposit === null">--</span>
        </template>
      </el-table-column>
      <el-table-column width="300" fixed="right" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" v-if="row.auditType == '01' && !row.isBlacklistCompany" type="primary" round
            @click="handleBtn(row, 'examine')">{{
              row.transferStatus == '1' ? '审核' : '审核记录' }}</el-button>
          <el-button size="mini" v-if="row.auditType == '01' && row.isBlacklistCompany" type="danger"
            round>在黑名单内</el-button>
          <el-button size="mini" v-if="row.transferStatus == '2'" type="warning" round
            @click="handleBtn(row, 'supplement')">补单查询</el-button>
          <el-button size="mini" v-if="row.transferStatus == '5' && row.bizType == '03' && row.transferVersion != 'v3'"
            type="danger" round @click="handleBtn(row, 'supplement')">重新打款</el-button>
          <el-button size="mini" type="success" round @click="handleBtn(row, 'remark')">{{ row.note ? '修改备注' : '添加备注'
          }}</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 黑名单 -->
    <GlobalTable ref="GlobalTable" v-else v-loading="loading" :columns="blacklistTable" :data="blackDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handblackChange">
      <el-table-column label="添加人" slot="createManager" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.createManager }}-{{ row.createManagerAcc }}</span>
        </template>
      </el-table-column>
      <el-table-column width="150" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="danger" round @click="undockBlack(row)">移除黑名单</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog title='添加黑名单商户' :visible.sync="commandShow" :close-on-click-modal="false" width="600px"
      @closed="commandClosed">
      <div class="enable-tip">
        <div>说明：添加黑名单后，该商户发起的提现申请将不显示【审核】按钮，移除后方可审核。</div>
      </div>
      <div>
        <GlobalForm ref="ruleform" :uploadLength="3" :init-data="initData" :form-rules="formRules"
          :form-item-list="formItemList" @handleConfirm="handleConfirm" confirmBtnName="确认">
          <el-form-item class="el_from" label-width="150px" label="选择商户" prop="companyId" slot="companyId">
            <el-select ref="select1" v-model="initData.companyId" placeholder="请输入查询或选择门店商家" filterable clearable
              @change="handleChange">
              <el-option v-for="item in shanpsSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <template slot="contacts" v-if="initData.companyId">
            <div class="generated">
              <div class="ga_content">
                <span>创建人：{{ compnyList.contactName || "--" }}</span>
                <span>联系电话：{{ compnyList.contactPhone || "--" }}</span>
              </div>
              <div>当前账户余额：{{ compnyList.balance }}（动态变化中）</div>
            </div>
          </template>
          <el-form-item class="el_from" label="加入黑名单原因" label-width="150px" prop="joinCause" slot="joinCause">
            <div class="qb_act">
              <div class="qukliy_box" @click="remarkAdd('商户暂停合作')">商户暂停合作</div>
              <div class="qukliy_box" @click="remarkAdd('商户有交易风险')">商户有交易风险</div>
            </div>
            <div>
              <el-input maxlength="100" type="textarea" placeholder="请输入原因说明（限100字）"
                v-model="initData.joinCause"></el-input>
            </div>
          </el-form-item>
          <el-button slot="btnSlot" @click="unfollow">取消</el-button>
        </GlobalForm>
      </div>
    </el-dialog>
    <!-- 添加黑名单商户操作验证 -->
    <el-dialog title='操作验证' :visible.sync="sdcommShow" :close-on-click-modal="false" width="600px" @closed="comClosed">
      <div style="text-align: center;margin-bottom: 20px;font-size: 16px;">
        <div>是否确认添加该商户进入黑名单？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commonclose">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="renewSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 移除黑名单 -->
    <el-dialog title='移除黑名单' :visible.sync="RemoveShow" :close-on-click-modal="false" width="600px" @closed="comClosed">
      <div class="enable-tip">
        <div>说明：移除后,该商户发起的提现可进行审核。</div>
      </div>
      <div style="text-align: center;margin-bottom: 20px;font-size: 16px;">
        <div>是否确认移除该商户黑名单？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="removeclose">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="undockSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 查看openid弹出框 -->
    <el-dialog class="acc_dialog" title='查看openID' :visible.sync="seeopendidShow" :close-on-click-modal="false"
      width="410px">
      <div style="margin-bottom: 20px">查看openID:</div>
      <div class="openSytle">
        <span id="123" style="margin-right: 20px;">{{ haveopenid }}</span>
        <el-tag @click="openidcopy('123')" size="mini">复制</el-tag>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="seeopendidShow = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" width="458px"
      @closed="handleDialogClosed">
      <div class="verify-title flex flex-ac"><span>申请信息</span><span class="verify-mark">{{ bizType == '01' ? '付款到用户' :
        bizType == '02' ? '个人提现' : bizType == '03' ? '公司提现' : '领取返佣' }}</span></div>
      <div class="mb-20" style="font-size:14px;color:#333333">
        <div class="mb-10 flex">
          <div style="flex: 0.5">
            <span>姓名：</span><span style="color:#666666;">{{ companyInfo.staffName }}</span>
          </div>
          <div style="flex: 0.5">
            <span>所属商家：</span><span style="color:#666666;">{{ companyInfo.companyName }}</span>
          </div>
        </div>
        <div class="mb-10 flex">
          <div style="flex: 0.5">
            <span>角色：</span><span style="color:#666666;">{{ companyInfo.staffRole == '01' ? '创建人' : companyInfo.staffRole
              == '02' ? '总监' : companyInfo.staffRole == '03' ? '门店店长' : '普通职员' }}</span>
          </div>
          <div style="flex: 0.5">
            <span>联系电话：</span><span style="color:#666666;">{{ companyInfo.staffPhone }}</span>
          </div>
        </div>
        <div class="mb-10 flex">
          <div style="flex: 0.5">
            <span>金额：</span><span style="color:#666666;">{{ companyInfo.amount }}</span>
          </div>
          <div style="flex: 0.5">
            <span>时间：</span><span style="color:#666666;">{{ companyInfo.createTime }}</span>
          </div>
        </div>
      </div>
      <div class="verify-title"><span>审核结果</span></div>
      <div class="mb-10" v-if="!verifyRecord">
        <el-radio v-model="auditStatusRadio" :disabled="isReject" label="2">审核通过</el-radio>
        <el-radio v-model="auditStatusRadio" :disabled="isReject" label="3">审核驳回</el-radio>
      </div>
      <div v-if="verifyRecord" class="mb-10" style="color:#333333">{{ auditStatusRadio == '3' ? '审核驳回' : '审核通过' }}</div>
      <div v-if="verifyRecord" class="mb-10" style="color:#333333">{{ auditStatusRadio == '3' ? '驳回原因' : '审核备注' }}：<span
          style="color:#666666;">{{ auditNote || '无' }}</span></div>
      <div v-if="!verifyRecord">
        <el-input maxlength="200" :disabled="isReject" type="textarea" rows="4"
          :placeholder="auditStatusRadio == '3' ? '请输入驳回原因（必填，驳回原因将反馈显示给申请人）' : '审核备注（非必填）'" show-word-limit
          v-model="auditNote"></el-input>
      </div>
      <div class="verify-title" v-if="verifyRecord"><span>日志记录</span></div>
      <div class="mb-20" v-if="verifyRecord&&!isPlatformAutoAudit"><span>审核人：</span><span style="color:#666666;">{{ auditLog.adminName
      }}</span><span style="margin-left:20px">审核时间：</span><span style="color:#666666;">{{ auditLog.auditTime }}</span>
      </div>
      <!--新增是否为系统自动审核-->
      <div class="mb-20" v-if="verifyRecord&&isPlatformAutoAudit"><span>审核人：</span><span style="color:#666666;">系统自动审核</span><span style="margin-left:20px">审核时间：</span><span style="color:#666666;">{{ auditLog.auditTime }}</span>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!verifyRecord">
        <el-button @click.native="handleClose">关闭</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="handleSuccess" v-if="!isReject">确定</el-button>
      </span>
    </el-dialog>
    <!-- 备注弹出框 -->
    <el-dialog class="acc_dialog" :title='RemarksTitle' :visible.sync="RemarksShow" :close-on-click-modal="false"
      width="410px" @closed="remarksClosed">
      <div style="margin-bottom: 20px">备注内容</div>
      <el-input maxlength="100" type="textarea" show-word-limit :rows="4" placeholder="请输入备注内容,最多100字" v-model="note">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="remarksClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="remarksSure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 补单查询弹出框 -->
    <el-dialog class="repair_dialog" :title='repairTitle' :visible.sync="repairShow" :close-on-click-modal="false"
      width="380px" @closed="repairClosed">
      <div class="repair-conent" v-loading="repairLoading" :element-loading-text="loadingText">
        <img v-if="!repairLoading && failed" src="@/assets/images/chenggong.png" alt="">
        <img v-if="!repairLoading && !failed" src="@/assets/images/guanbi.png" alt="">
        <div v-if="!repairLoading" style="margin-top: 10px">{{ repairResult }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formValidateMobileLandline } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "Table",
  props: {
    accountType: {
      require: true,
      type: String
    },
  },
  data() {
    return {
      isExport: false,
      RemoveShow: false,
      sdcommShow: false,
      initData: {
        companyId: "",
        command: "",
        joinCause: "",
      },
      commandTitle: "",
      command: "",
      shanpsSelectList: [],
      compnyList: {
        balance: 0,
        contactName: "",
        contactPhone: "",
      },
      staffList: [],
      params: {},
      NumMoney: "",
      formItemList: [
        { slotName: 'companyId' },
        { slotName: 'contacts' },
        { slotName: "joinCause" },
      ],

      formRules: {
        companyId: [
          {
            required: true,
            trigger: "change",
            message: "选择门店商"
          },
        ],
        joinCause: [
          {
            required: true,
            trigger: "blur",
            message: "输入加入黑名单原因（100字内）",
          },
        ],
      },
      commandShow: false,
      seeopendidShow: false,
      loading: false,
      butLoading: false,
      repairLoading: false,
      isReject: false,
      seachDataList: [],
      blackDataList: [],//黑名单
      blacktotal: 0,//黑名单总数
      transferStatus: '',
      auditStatusRadio: '2',
      transferNo: "",
      auditNote: "",
      numView: {},
      companyInfo: {},
      auditLog: {},
      page: {
        pageNum: 1,
        pageSize: 10
      },
      pageNum: 1,
      tableColumns: [
        { label: "归属商家", prop: "companyName" },
        { slotName: "bizType" },
        { label: "出账单号", prop: "transferNo" },
        { slotName: "relationNo" },
        { label: "姓名", prop: "staffName" },
        { slotName: "staffRole" },
        { slotName: "staffPhone" },
        { label: "创建时间", prop: "createTime" },
        { label: "更新时间", prop: "updateTime" },
        { slotName: "amount" },
        { slotName: "transferStatus" },
        { slotName: "auditType" },
        { slotName: "note" },
        { slotName: "errorMsg" },
        { slotName: "transferChannel" },
        { slotName: "firstPrice" },//是否首次提现
        { slotName: "operation" }
      ],
      blacklistTable: [
        { label: "商户名称", prop: "companyName" },
        { label: "联系人", prop: "contact" },
        { label: "联系人电话", prop: "phone" },
        { slotName: "createManager" },
        { label: "添加时间", prop: "createTime" },
        { label: "添加原因", prop: "joinCause" },
        { slotName: "operation" }
      ],
      companyId: "",
      // 弹出框
      RemarksShow: false,
      RemarksTitle: '',
      note: "",
      verifyRecord: false,
      dialogTitle: "",
      dialogVisible: false,
      repairShow: false,
      repairTitle: "",
      repairResult: "",
      loadingText: "",
      failed: true,
      bizType: "",
      haveopenid: "",
      isPlatformAutoAudit:false,//系统是否自动审核
    };
  },
  created() {
    this.handleCurrentChange();
    this.getMerchantList()
    this.handblackChange()
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal)
      this.page.pageNum = 1
      this.handleCurrentChange()
      this.handblackChange()
    },
  },
  methods: {
    //快捷备注
    remarkAdd(mark) {
      this.initData.joinCause = this.initData.joinCause + mark
    },
    //关闭动态口令
    removeclose() {
      this.RemoveShow = false,
        this.command = ''
    },
    //移出黑名单
    undockBlack(data) {
      console.log(data);
      this.RemoveShow = true
      this.companyId = data.companyId
    },
    //关闭动态口令
    commonclose() {
      this.sdcommShow = false;
      this.command = ''
    },
    //
    comClosed() {
      this.command = ''
    },
    //加入黑名单弹窗
    putBlacklist() {
      this.getMerchantList()
      this.commandShow = true
    },
    //加黑名单
    renewSubmit() {
      if (!this.command) {
        return this.$message.error("请输入动态口令");
      }
      this.params.command = this.command
      _api.joinBlacklist(this.params).then(res => {
        if (res.code == 1) {
          this.sdcommShow = false
          this.commandShow = false
          this.$message({
            message: '黑名单加入成功！',
            type: 'success'
          });
          this.handleCurrentChange()
          this.handblackChange()
        }
      })
    },
    //黑名单移除
    undockSubmit() {
      if (!this.command) {
        return this.$message.error("请输入动态口令");
      }
      _api.removeBlacklist({
        command: this.command,
        companyId: this.companyId
      }).then(res => {
        if (res.code == 1) {
          this.RemoveShow = false
          this.$message({
            message: '黑名单移除成功！',
            type: 'success'
          });
          this.handleCurrentChange()
          this.handblackChange()
        }
      })
    },
    // 获取回收商
    getMerchantList() {
      _api.getSelectListInBlacklist().then((res) => {
        if (res.code === 1) {
          this.shanpsSelectList = res.data
        }
      })
    },
    //选择商家
    handleChange(val) {
      this.initData.companyId = val
      if (val) {
        this.getCompanyAccount(val)
      } else {
        this.compnyList = {
          balance: 0,
          contactName: "",
          contactPhone: "",
        }
      }
    },
    getCompanyAccount(id) {
      _api.getCompanyAccount({ companyId: id }).then((res) => {
        if (res.code === 1) {
          this.compnyList.balance = res.data.companyAccount.balance
          this.compnyList.contactName = res.data.contactName
          this.compnyList.contactPhone = res.data.contactPhone
        }
      })
    },
    //取消黑名单
    unfollow() {
      this.commandShow = false
    },
    handleConfirm(data) {
      console.log(data);
      this.params = {
        companyId: this.initData.companyId,
        joinCause: data.joinCause
      };
      console.log(this.params);
      this.sdcommShow = true
    },
    commandClosed() {
      this.initData = {
        companyId: "",
        command: "",
        joinCause: "",
      }
    },
    //复制opendid
    openidcopy(e) {
      const spanText = document.getElementById(e).innerText;
      const oInput = document.createElement("input");
      oInput.value = spanText;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
    //查看openID
    examineId(row) {
      this.seeopendidShow = true
      this.haveopenid = row.openid
    },
    //黑名单
    handblackChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const blackRequest = {
        companyName: this.SeachParams.companyName,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.getBlacklist(blackRequest).then(res => {
        if (res.code === 1) {
          this.blackDataList = res.data.records;
          this.blacktotal = res.data.total;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false
        }
      });
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        bizType: this.SeachParams.bizType,
        transferNo: this.SeachParams.transferNo,
        relationNo: this.SeachParams.relationNo,
        staffName: this.SeachParams.staffName,
        staffRole: this.SeachParams.staffRole,
        staffPhone: this.SeachParams.staffPhone,
        auditType: this.SeachParams.auditType,
        transferChannel: this.SeachParams.transferChannel,
        transferStatus: this.transferStatus,
        companyName: this.SeachParams.companyName,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.transferList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.list.records;
          this.numView = res.data.numView;
          this.page.total = res.data.list.total;
          this.page.pageNum = res.data.list.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    btnClick(type) {
      this.transferStatus = type
      this.page.pageNum = 1
      if (type == '8') {
        this.handblackChange()
      } else {
        this.handleCurrentChange()
      }
    },
    // 弹出弹框
    handleBtn(row, type) {
      console.log(row);
      this.transferNo = row.transferNo
      this.isReject = false
      this.isPlatformAutoAudit=row.isPlatformAutoAudit
      if (type == 'remark') {
        if (row.note) {
          this.RemarksTitle = '修改备注'
        } else {
          this.RemarksTitle = '添加备注'
        }
        this.note = row.note
        this.RemarksShow = true
      } else if (type == 'examine') {
        this.companyInfo = {
          companyName: row.companyName,
          staffName: row.staffName,
          staffRole: row.staffRole,
          staffPhone: row.staffPhone,
          amount: row.amount,
          createTime: row.createTime,
        }
        this.bizType = row.bizType
        if (row.transferStatus == '1') {
          this.dialogTitle = '审核-' + row.transferNo
          this.verifyRecord = false
        } else {
          this.verifyRecord = true
          this.dialogTitle = '审核记录-' + row.transferNo
          this.auditStatusRadio = row.transferStatus
          this.auditNote = row.transferStatus == '3' ? row.rejectCause : row.auditNote
          this.auditLog = {
            adminName: row.adminName,
            auditTime: row.auditTime
          }
        }
        this.dialogVisible = true
      } else if (type == 'supplement') {
        this.repairShow = true
        this.failed = true
        if (row.transferStatus == 2) {
          this.loadingText = '正在查询交易结果，请稍后'
          this.repairLoading = true
          this.repairTitle = '补单查询-' + row.transferNo
          _api.transferQuery({ transferNo: this.transferNo }).then(res => {
            console.log(res);
            this.repairResult = res.msg || '补单查询成功'
            this.repairLoading = false
          }).catch(err => {
            console.log(err);
            this.repairResult = err.msg || '补单查询成功'
            this.repairLoading = false
          })
        } else if (row.transferStatus == 5) {
          this.loadingText = '正在重新打款，请稍后'
          this.repairLoading = true
          this.repairTitle = '重新打款-' + row.transferNo
          _api.transferManual({ orderNo: this.transferNo }).then(res => {
            console.log(res);
            if (res.code === 1) {
              this.repairResult = res.msg || '重新打款成功'
              this.failed = true
            } else {
              this.repairResult = res.msg || '重新打款失败'
              this.failed = false
            }
            this.repairLoading = false
          }).catch(err => {
            console.log(err);
            this.failed = false
            this.repairResult = err.msg || '重新打款失败'
            this.repairLoading = false
          })
        }
        console.log('补单查询');
      }
    },
    // 备注确定提交
    remarksSure() {
      this.butLoading = true
      _api.transferNote({ transferNo: this.transferNo, note: this.note }).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg)
          this.RemarksShow = false
          this.handleCurrentChange()
        }
        this.butLoading = false
      }).catch(err => {
        this.butLoading = false
      })
    },
    remarksClosed() {
      this.RemarksShow = false
      this.note = ""
    },
    handleDialogClosed() {
      this.dialogVisible = false
      this.auditStatusRadio = "2"
      this.auditNote = ""
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleSuccess() {
      if (this.auditStatusRadio == '3' && !this.auditNote) {
        this.$message.error('审核驳回必须填写驳回原因');
        return false
      }
      this.butLoading = true
      let params = {
        transferNo: this.transferNo,
        state: this.auditStatusRadio,
      }
      if (this.auditStatusRadio == '3') {
        params.rejectCause = this.auditNote
      } else {
        params.auditNote = this.auditNote
      }
      _api.payAudit(params).then(res => {
        if (res.code === 1) {
          if (res.data && res.data.isReject) {
            this.isReject = true
            this.auditStatusRadio = '3'
            this.auditNote = res.data.errorMsg
            this.$message.error(res.data.errorMsg + ',已经驳回')
          } else {
            this.isReject = false
            this.dialogVisible = false
            this.$message.success(res.msg || '操作成功')
          }
          this.handleCurrentChange()
        }
        this.butLoading = false
      }).catch(err => {
        this.butLoading = false
      })
    },
    repairClosed() {
      this.repairShow = false
    },
    toOrderDetails(row) {
      this.$router.push({ path: "/RecallOrder/Details", query: { type: 'edit', id: row.relationNo } })
    },
    // 导出
    clickExcel() {
      const params = {
        bizType: this.SeachParams.bizType,
        transferNo: this.SeachParams.transferNo,
        relationNo: this.SeachParams.relationNo,
        staffName: this.SeachParams.staffName,
        staffRole: this.SeachParams.staffRole,
        staffPhone: this.SeachParams.staffPhone,
        auditType: this.SeachParams.auditType,
        transferChannel: this.SeachParams.transferChannel,
        transferStatus: this.transferStatus,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      }
      this.isExport=true
      _api.transferExcel(params).then(res => {
        console.log(res)
        // if (this.SeachParams.startTime) {
        const content = res;
        const blob = new Blob([content]);
        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => { //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || '导出失败')
            }
          } catch (err) {
            const fileName = "出账账单列表.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        })
        reader.readAsText(blob, 'utf-8'); // 设置读取的数据以及返回的数据类型为utf-8
        this.isExport=false
      }).catch(err => {
        this.isExport=false
        console.log(err)
      });
    },
    // 999以下的正常显示
    // 1000-9999显示1k，1.1k，9.9k
    // 10000以上的显示1w，9.9w，99w，999w
    numHandle(num) {
      if (num <= 999) {
        return num
      } else if (num >= 1000 && num <= 9999) {
        if ((num / 1000).toString().indexOf('.') < 0) {
          return (num / 1000) + 'k'
        } else {
          return this.formatDecimal(num / 1000, 1) + 'k'
        }
      } else {
        if ((num / 10000).toString().indexOf('.') < 0) {
          return (num / 10000) + 'w'
        } else {
          return this.formatDecimal(num / 10000, 1) + 'w'

        }
      }
    },
    formatDecimal(num, decimal) {
      num = num.toString()
      let index = num.indexOf('.')
      if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
      } else {
        num = num.substring(0)
      }
      num = parseFloat(num).toFixed(decimal).toString()
      if (num[num.indexOf('.') + 1] == 0) {
        num = num.substring(0, num.indexOf('.'))
      }
      return num
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .qb_act {
    display: flex;
    margin: 10px 0;

    .qukliy_box {
      font-size: 13px;
      margin-right: 10px;
      line-height: 13px;
      border-radius: 5px;
      padding: 5px 10px;
      background-color: #e4ecfd;
      color: rgb(9, 129, 255);
    }
  }

  .generated {
    margin-left: -30px;
  }

  .ga_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // 备注说明
  .enable-tip {
    color: #FF8080;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .el_from {
    margin-left: -120px;
    margin-bottom: 0;
  }

  /deep/.el-select {
    width: 100% !important;
  }

  .command {
    display: flex;
    align-items: center;

    /deep/.el-input {
      width: 300px;
    }
  }

  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .openSytle {
    padding: 20px 10px;
    background-color: #F5F5F5;
  }

  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }

  .button_top {
    margin-bottom: 20px;
    ;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    width: 1120px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981FF;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  .verify-title {
    color: #0981FF;
    line-height: 16px;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .repair_dialog {
    /deep/.el-dialog__body {
      padding: 0 20px 0 20px;
    }

    /deep/.el-loading-mask .el-loading-spinner {
      margin-top: -35px !important;
    }
  }

  .repair-conent {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .verify-mark {
    width: max-content;
    font-size: 12px;
    color: #fff;
    padding: 0 6px;
    background: #FF687B;
    border-radius: 10px 8px 8px 0px;
    margin-left: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .flex-ac {
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
