<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="90"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
import moment from 'moment'
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String
    },
  },
  data() {
    return {
      initData: {
        entryTime: [
          moment()
            .day(moment().day()-6)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      formItemList: [
        {
          key: 'orderType',
          type: 'select',
          labelName: '入账类型',
          placeholder: '请选择',
          option:[{
            value:'refund',
            label:"退款",
          },
          {
            value:'recharge',
            label:"外部充值旧机款",
          },
          ]
        },
        {
          key: "orderNo",
          type: "input",
          labelName: "入账单号",
          placeholder: "请输入入账单号",
        },
        {
          key: "relationNo",
          type: "input",
          labelName: "关联订单号",
          placeholder: "请输入关联订单号",
        },
        // {
        //   key: "companyName",
        //   type: "input",
        //   labelName: "商家名称",
        //   placeholder: "请输入商家名称",
        // },
        {
          key: 'merchantId',
          type: 'selectFilterable',
          labelName: '回收商家',
          placeholder: '请选择',
          option: [],
          clear:true,
        },
        {
          key: 'companyId',
          type: 'selectFilterable',
          labelName: '门店商家',
          placeholder: '请选择',
          option: [],
          clear:true,
        },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "创建时间",
          placeholder: "请选择创建时间",
          valueFormat: "timestamp",
          clearable: true,
        },
        {
          key: 'payMethod',
          type: 'select',
          clearFun: () => false,
          labelName: '付款方式',
          placeholder: '请选择付款方式',
          option: [
            {
              value: '',
              label: '全部',
            },
            {
              value: 'weixin_qrcode',
              label: '微信',
            },
            {
              value: 'alipay_qrcode',
              label: '支付宝',
            }
          ],
        },
        {
          key: 'paymentPassage',
          type: 'select',
          clearFun: () => false,
          clear:true,
          labelName: '支付通道',
          placeholder: '请选择支付通道',
          option: [
            {
              value: '',
              label: '全部',
            },
            {
              value: 'wechat',
              label: '微信官方',
            },
            {
              value: 'yeepay',
              label: '易宝',
            },{
              value: 'alipay',
              label: '支付宝官方',
            }
          ],
        },
      ],
    };
  },
  created() {
    this.getMerchantList()
    this.listSimpleMerchantView()
    this.handleConfirm(this.initData)
  },
  watch:{
  },
  methods: {
    // 回收商家
    listSimpleMerchantView(){
      _api.listSimpleMerchantView({ pageNum: 1, pageSize: 999 }).then((res) => {
        if (res.code === 1) {
          const hsIndex = this.formItemList.findIndex((v) => v.key === "merchantId");
          this.formItemList[hsIndex].option = res.data.map((v) => {
            return {
              label: v.merchantName,
              value: v.merchantId,
            };
          });
        }
      });
    },
    // 门店商家下拉
    getMerchantList(){
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          const index = this.formItemList.findIndex(
            (v) => v.key === "companyId"
          );
          this.formItemList[index].option = res.data.map((v) => {
            return {
              label: v.name,
              value: v.id,
            };
          });
        }
      })
    },
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {};
      if (data) {
        SeachParams = {
          merchantId:data.merchantId,
          companyId:data.companyId,
          payMethod:data.payMethod,
          paymentPassage: data.paymentPassage,
          orderType: data.orderType,
          orderNo: data.orderNo,
          // companyName: data.companyName,
          relationNo: data.relationNo,
          startTime: data.entryTime ? moment(data.entryTime[0]).format("x") : "",
          endTime: data.entryTime ? moment(data.entryTime[1]).format("x") : "",
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams. disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        },

      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
