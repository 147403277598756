<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
            <div :class="orderStatus=== ''? 'active' : 'none'" @click="btnClick('')">
              全部({{numView.allNum?numHandle(numView.allNum) : 0}})
            </div>
            <div :class="orderStatus=== '01'? 'active' : 'none'" @click="btnClick('01')">
              已到账({{numView.completedNum?numHandle(numView.completedNum) : 0}})
            </div>
            <div :class="orderStatus=== '00'? 'active' : 'none'" @click="btnClick('00')">
              未到账({{numView.nonNum?numHandle(numView.nonNum) : 0}})
            </div>
            <div :class="orderStatus=== '02'? 'active' : 'none'" @click="btnClick('02')">
              已失效({{numView.invalidNum?numHandle(numView.invalidNum) : 0}})
            </div>
        </div>
      </div>
      <div>
        <el-button size="small" :loading="isExport" type="success" icon="el-icon-download" @click="clickExcel">导出账单</el-button>
      </div>
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column
        label="类型"
        slot="orderType"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{row.orderType=='recharge'?'外部充值旧机款':'退款'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="商户名称"
        slot="merchantName"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-tooltip
              class="item"
              effect="dark"
              v-if="row.userChannel == 'Merchant' && row.merchantName"
              :content="row.merchantName"
              placement="top"
          >
              <div class="Remarks">
              {{ row.merchantName }}
              </div>
          </el-tooltip>
          <el-tooltip
              class="item"
              effect="dark"
              v-if="row.userChannel == 'Store' && row.companyName"
              :content="row.companyName"
              placement="top"
          >
              <div class="Remarks">
              {{ row.companyName }}
              </div>
          </el-tooltip>
          <span v-else-if="!row.companyName && !row.merchantName">--</span>
        </template> 
      </el-table-column>
      <el-table-column
        label="商户类型"
        slot="userChannel"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{row.userChannel == 'Store' ? '门店商' : row.userChannel == 'Merchant' ? '回收商' : '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="关联订单号"
        slot="relationNo"
        align="center"
      >
        <template slot-scope="{ row }">
          <div style="color: #0981ff;cursor: pointer;" @click="toOrderDetails(row)"><span style="border-bottom: 1px solid #0981ff">{{row.relationNo}}</span></div>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        slot="orderStatus"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{row.orderStatus=='00'?'未到账':row.orderStatus=='01'?'已到账':'已失效'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="付款方式"
        slot="payMethod"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{row.payMethod=='alipay_qrcode'?'支付宝':row.payMethod=='weixin_qrcode'?'微信':'--'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付通道" slot="Paymentchannel" align="center" width="90px">
        <template slot-scope="{ row }">
          <span>{{ row.paymentPassage=='wechat'?'微信官方':row.paymentPassage=='alipay'?'支付宝官方':'易宝' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="易宝单号" slot="YibaoOrderNo" align="center" width="90px">
        <template slot-scope="{ row }">
          <span>{{ row.yeepayOrderId ||"--"}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="备注"
        width="130rpx"
        slot="note"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-tooltip
              class="item"
              effect="dark"
              v-if="row.note"
              :content="row.note"
              placement="top"
          >
              <div class="Remarks">
              {{ row.note }}
              </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column width="100" fixed="right" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" v-if="row.transferStatus=='2'" type="warning" round @click="handleBtn(row,'supplement')">补单查询</el-button>
          <el-button size="mini" type="success" round @click="handleBtn(row,'remark')">{{row.note?'修改备注':'添加备注'}}</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 备注弹出框 -->
    <el-dialog
      class="acc_dialog"
      :title='RemarksTitle'
      :visible.sync="RemarksShow"
      :close-on-click-modal="false"
      width="410px"
      @closed="remarksClosed"
    >
      <div style="margin-bottom: 20px">备注内容</div>
      <el-input
        maxlength="100"
        type="textarea"
        show-word-limit
        :rows="4"
        placeholder="请输入备注内容,最多100字"
        v-model="note"
      >
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="remarksClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="remarksSure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 补单查询弹出框 -->
    <el-dialog
      class="repair_dialog"
      :title='repairTitle'
      :visible.sync="repairShow"
      :close-on-click-modal="false"
      width="380px"
      @closed="repairClosed"
    >
      <div class="repair-conent" v-loading="repairLoading" :element-loading-text="loadingText">
        <img v-if="!repairLoading" src="@/assets/images/chenggong.png" alt="">
        <div v-if="!repairLoading" style="margin-top: 10px">{{repairResult}}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  props: {
    accountType: {
      require: true,
      type: String
    },
  },
  data() {
    return {
      isExport: false,
      loading: false,
      seachDataList: [],
      orderStatus: '',
      butLoading: false,
      numView: {},
      page:{
        pageNum:1,
        pageSize:10
      },
      pageNum:1,
      tableColumns: [
        { slotName: "orderType" },
        { slotName: "merchantName" },
        { slotName: "userChannel"},//商户类型
        { label: "入账单号", prop: "orderNo" },
        { slotName: "relationNo" },
        { label: "创建时间", prop: "createTime" },
        { label: "更新时间", prop: "updateTime" },
        { label: "金额", prop: "price" },
        { slotName: "orderStatus" },
        { slotName: "payMethod" },
        { slotName: "Paymentchannel" },//支付通道
        { slotName: "YibaoOrderNo" },//易宝单号
        { label: "第三方业务单号", prop: "outRelationNo" },
        { slotName: "note" },
        { slotName: "operation" }
      ],
      // 弹框
      RemarksTitle: "",
      RemarksShow: false,
      note: "",
      repairShow: false,
      repairTitle: "",
      repairLoading: false,
      loadingText: false,
      repairResult: ""
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.page.pageNum = 1
      this.handleCurrentChange()
    },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        merchantId: this.SeachParams.merchantId,
        companyId: this.SeachParams.companyId,
        payMethod: this.SeachParams.payMethod,
        paymentPassage: this.SeachParams.paymentPassage,
        orderStatus: this.orderStatus,
        // orderType: 'refund',
        orderType: this.SeachParams.orderType || '',
        orderNo: this.SeachParams.orderNo,
        // companyName: this.SeachParams.companyName,
        relationNo: this.SeachParams.relationNo,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.wechatRefundList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.list.records;
          this.numView = res.data.numView;
          this.page.total = res.data.list.total;
          this.page.pageNum = res.data.list.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
      });
    },
    remarksClosed() {
      this.RemarksShow = false
      this.note = ""
    },
    // 弹出弹框
    handleBtn(row,type){
      console.log(row);
      this.transferNo = row.orderNo
      if(type == 'remark'){
        if(row.note){
            this.RemarksTitle = '修改备注'
        }else{
            this.RemarksTitle = '添加备注'
        }
        this.note = row.note
        this.RemarksShow = true
      }else if(type == 'supplement'){
        this.repairShow = true
        this.loadingText = '正在查询交易结果，请稍后'
        this.repairLoading = true
        this.repairTitle = '补单查询-'+row.orderNo
        _api.refundQuery({transferNo:this.transferNo}).then(res => {
          console.log(res);
          this.repairResult = res.msg || '补单查询成功'
          this.repairLoading = false
        }).catch(err => {
          console.log(err);
          this.repairResult = err.msg || '补单查询成功'
          this.repairLoading = false
        })
        console.log('补单查询');
      }
    },
    repairClosed() {
      this.repairShow = false
    },
    // 备注确定提交
    remarksSure() {
        this.butLoading = true
        _api.noteRefund({transferNo:this.transferNo,note:this.note}).then(res => {
            if (res.code === 1) {
                this.$message.success(res.msg)
                this.RemarksShow = false
                this.handleCurrentChange()
            }
            this.butLoading = false
        }).catch(err => {
            this.butLoading = false
        })
    },
    btnClick(type){
      this.orderStatus = type
      this.page.pageNum = 1
      this.handleCurrentChange()
    },
    toOrderDetails(row) {
      this.$router.push({path:"/RecallOrder/Details",query:{type:'edit',id: row.relationNo}})
    },
    // 导出
    clickExcel() {
      const params = {
        merchantId: this.SeachParams.merchantId,
        companyId: this.SeachParams.companyId,
        payMethod: this.SeachParams.payMethod,
        orderStatus: this.orderStatus,
        // orderType: 'refund',
        orderType: this.SeachParams.orderType || '',
        orderNo: this.SeachParams.orderNo,
        // companyName: this.SeachParams.companyName,
        relationNo: this.SeachParams.relationNo,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      }
      this.isExport=true
      _api.refundExcel(params).then(res => {
        console.log(res)
        // if (this.SeachParams.startTime) {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend",  ()=> { //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || '导出失败')
              }
            } catch (err){
              const fileName = "入账账单列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          })
          reader.readAsText(blob, 'utf-8'); // 设置读取的数据以及返回的数据类型为utf-8
          this.isExport=false
      }).catch(err => {
        console.log(err)
        this.isExport=false
      });
    },
    // 999以下的正常显示
    // 1000-9999显示1k，1.1k，9.9k
    // 10000以上的显示1w，9.9w，99w，999w
    numHandle(num){
      if(num<=999){
        return num
      }else if(num>=1000 && num<=9999){
        if((num/1000).toString().indexOf('.') < 0){
          return (num/1000) + 'k'
        }else{
          return this.formatDecimal(num/1000,1) + 'k'
        }
      }else{
        if((num/10000).toString().indexOf('.') < 0){
          return (num/10000) + 'w'
        }else{
          return this.formatDecimal(num/10000,1) + 'w'
          
        }
      }
    },
    formatDecimal(num, decimal) {
      num = num.toString()
      let index = num.indexOf('.')
      if (index !== -1) {
          num = num.substring(0, decimal + index + 1)
      } else {
          num = num.substring(0)
      }
      num = parseFloat(num).toFixed(decimal).toString()
      if(num[num.indexOf('.')+1] == 0){
        num = num.substring(0, num.indexOf('.'))
      }
      return num
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
    // 备注说明
    .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
    }
    .operation-btn{
        /deep/.el-button{
            margin-left: 0;
        }
    }
    .button_top{
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn{
    width: 700px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;
    .active{
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981FF;
      border-radius: 20px;
    }
    .none{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }
  .repair_dialog{
    /deep/.el-dialog__body{
      padding: 0 20px 0 20px;
    }
    /deep/.el-loading-mask .el-loading-spinner{
      margin-top: -35px !important;
    }
  }
  .repair-conent{
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .mb-10{
    margin-bottom: 10px;
  }
  .mb-20{
    margin-bottom: 20px;
  }
  .flex{
    display: flex;
    justify-content: space-between;
  }
}
</style>
